import React, { useState, useRef, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { parseACF } from '../utils'
import DefaultPage from '../templates/default-page'
import { useMount } from 'react-use'
import { sliderContext } from '../components/ImageSlider/SliderProvider'

const Index = props => {
    const { active, setActive } = useContext(sliderContext)

    const data = {
        title: 'Home',
        slug: '/',
        ...parseACF(useStaticQuery(query), 'allWordpressDefaultpages')
    }

    useMount(() => {
        if (!active) {
            setActive(true)
        }
    })

    return (
        <DefaultPage pageData={data} />
    )
}

export const query = graphql`
    query {
        allWordpressDefaultpages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
